import { useSelector } from "react-redux";
import { useLocation,  Navigate } from "react-router-dom";
// import Home from "../pages/home/Home";

const RequireAuth = ({children}) => {
 const location = useLocation();
 const token = useSelector(state=> state.auth.token)
//  console.log(token);
 return (
   token ? children : <Navigate to="/login" replace />
 )


};

export default RequireAuth
