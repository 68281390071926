import { NavLink } from "react-router-dom";
import "./menu.css";
import homeIcon from "../../img/home.svg";
import { logOut, selectCurrentToken } from "../../redux/users/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersCount, usersCount } from "../../redux/users/usersSlice";
import { useEffect } from "react";

const Menu = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const handleLogOut = () => {
    dispatch(logOut());
  };
  useEffect(() => {
    dispatch(fetchUsersCount(token));
  }, [token, dispatch]);
  return (
    <div className="menu">
      <div className="item">
        <NavLink to={"/"} className="listItem">
          <img src={homeIcon} alt="" />
          <span className="listItemTitle">Home</span>
        </NavLink>
      </div>
      <div className="item">
        <NavLink to={"/users"} className="listItem">
        <i className="fa-solid fa-users"> </i> <span className="listItemTitle"> Users</span>
        </NavLink>
      </div>
      <div className="item">
        <button className="listItem" onClick={handleLogOut}>
          <i
            className="fa-solid fa-arrow-right-from-bracket"
            onClick={logOut}
          ></i>
          <span className="listItemTitle">Log Out</span>
        </button>
      </div>
    </div>
  );
};

export default Menu;
