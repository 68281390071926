import React, { useEffect, useState } from "react";
import './home.css'
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersCount, usersCount } from "../../redux/users/usersSlice";
import { loading, selectCurrentToken } from "../../redux/users/authSlice";
import AddProduct from "../../components/modal/AddProduct";
import ClipLoader from "react-spinners/BeatLoader";
import Products from "../products/Products";
import usersIcon from "../../img/icon/user.svg"
import Histogram from "../../components/histogram/Histogram";

function Home() {
  const token = useSelector(selectCurrentToken)
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  useEffect(()=>{
    dispatch(fetchUsersCount(token))
    // console.log('home page', token);
  },[dispatch])
  const loading = useSelector(state=> state.users.loading)
  const userCount = useSelector(usersCount)
  // if (loading) {
  //   return <div className='flex'> <ClipLoader/></div>
  // }
  return (
      <>
      <div className="add-product"><button  onClick={()=>setOpen(true) }type="button">Add Product</button></div>
      {open && <AddProduct setOpen={setOpen}/>}
      <div className="home">
        {/* <div className="box numberOfUsers"><span>Total Users: <b> {userCount?.count}</b></span></div> */}
        <div className="box addProduct"><Products/></div>
        <div className="box usersProducs"><Histogram/></div>
        {/* <div className="box histogram "></div> */}
      </div>
</>
  );
}

export default Home;
