// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
 /* BG */
--main-bg: #2a3447;
--soft-bg: #384256;
--dark-bg: #222b3c;
/* TEXT */
--main-color: white;
--soft-color: #ddd;
--dark-color: #2a3447;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.App {
  display: flex;
  justify-content: center;
  font-family: "Inter", sans-serif;
  background-color: var(--main-bg);
  /* color:var(--main-color); */
  height: 100vh;
}

.flex{
  display:flex;
  justify-content: center;
  align-items: center;
}
.main {
  font-family: "Inter", sans-serif;
  /* background-color: #2a3447; */
  color: #2a3447;
}
.container{
  display: flex;
}
.menuContainer {
  width: 200px;
  padding: 5px 20px;
  border-right: 2px solid #384256;
  min-height: 100vh;
  background-color: #2a3447;
  color:#fff;
}
.contentContainer{
  padding: 5px 20px;
     width: 100%;
 }

 .text-center{
  text-align: center !important;
 }
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;CACC,OAAO;AACR,kBAAkB;AAClB,kBAAkB;AAClB,kBAAkB;AAClB,SAAS;AACT,mBAAmB;AACnB,kBAAkB;AAClB,qBAAqB;AACrB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gCAAgC;EAChC,gCAAgC;EAChC,6BAA6B;EAC7B,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,+BAA+B;EAC/B,cAAc;AAChB;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,+BAA+B;EAC/B,iBAAiB;EACjB,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,iBAAiB;KACd,WAAW;CACf;;CAEA;EACC,6BAA6B;CAC9B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');\n:root{\n /* BG */\n--main-bg: #2a3447;\n--soft-bg: #384256;\n--dark-bg: #222b3c;\n/* TEXT */\n--main-color: white;\n--soft-color: #ddd;\n--dark-color: #2a3447;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\n.App {\n  display: flex;\n  justify-content: center;\n  font-family: \"Inter\", sans-serif;\n  background-color: var(--main-bg);\n  /* color:var(--main-color); */\n  height: 100vh;\n}\n\n.flex{\n  display:flex;\n  justify-content: center;\n  align-items: center;\n}\n.main {\n  font-family: \"Inter\", sans-serif;\n  /* background-color: #2a3447; */\n  color: #2a3447;\n}\n.container{\n  display: flex;\n}\n.menuContainer {\n  width: 200px;\n  padding: 5px 20px;\n  border-right: 2px solid #384256;\n  min-height: 100vh;\n  background-color: #2a3447;\n  color:#fff;\n}\n.contentContainer{\n  padding: 5px 20px;\n     width: 100%;\n }\n\n .text-center{\n  text-align: center !important;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
