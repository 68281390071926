import './footer.css'
import logo from '../../img/logo-siin.png'

const Footer = () => {
 return (
   <div className="footer">
     <span><img src={logo} alt="logo" width={'90px'}/></span>
     <span>© SiinLab Admin Dashboard</span>
   </div>
 )
}

export default Footer
