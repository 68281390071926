import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import Footer from "./components/footer/Footer";
import Login from "./components/auth/Login";
import Home from "./pages/home/Home";
import Menu from "./components/menu/Menu";
import RequireAuth from "./authentication/RequireAuth"; // Import the RequireAuth component
import Products from "./pages/products/Products";
import UsersList from "./pages/users/UsersList";

function App() {
  const Layout = () => {
    return (
      <div className="main">
        <div className="container">
          <div className="menuContainer">
            <Menu />
          </div>
          <div className="contentContainer">
            {/* <QueryClientProvider client={queryClient}> */}
            <Outlet />
            {/* </QueryClientProvider> */}
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout /> ,
      children: [
        {
          path: "/",
          element:(<RequireAuth><Home /></RequireAuth>)
            ,
        },
        {
          path: "/product",
          element: (
            <RequireAuth><Products /></RequireAuth>
          ),
        },
        {
          path: "/users",
          element: (
            <RequireAuth><UsersList /></RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
