import { createAction, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addProduct,
  deleteProduct,
  fetchNumProductUsing,
  fetchProducts,
  updateProduct,
} from "./productServices";

// ADD PRODUCT
export const addProductAsync = createAsyncThunk(
  "products/addProductAsync",
  async ({productData, token}) => {
    try {

    const response = await addProduct(productData, token);
    console.log(token);
    if (response) {
      console.log(response);
    }
    return response;

    } catch (error) {


  }}
);

// UPDATE PRODUCT
export const updateProductAsync = createAsyncThunk(
  "products/updateProductAsync",
  async ({  productData, token }) => {
    // console.log({ productId, productData, token });
    try {
      const response = await updateProduct({ productData, token });
      // console.log(response);
      return response;
    } catch (error) {

    }
  }
);

// DELETE PRODUCT

export const deleteProductAsync = createAsyncThunk(
  "products/deleteProductAsync",
  async ({product_id, token}) => {
    const response = await deleteProduct(product_id, token);
    console.log(response);
    return response;
  }
);

export const fetchAllProducts = createAsyncThunk(
  "products/fetchAllProducts",
  async () => {
    const response = await fetchProducts();
    // console.log(response);
    return response;
  }
);

export const fetchNumProductUses = createAsyncThunk(
  "products/fetchNumProductUses",
  async ({ product_id, token }, thunkAPI) => {
    try {
      const response = await fetchNumProductUsing(product_id, token);
      return response;
    } catch (error) {
      console.error("Error fetching numUsers:", error);
      // Dispatch an action to handle the error (you can define a new action for this)
      thunkAPI.dispatch(fetchNumProductUsesFailed(error));
      throw error; // Re-throw the error to handle it elsewhere if needed
    }
  }
);

export const fetchNumProductUsesFailed = createAction("products/fetchNumProductUsesFailed");

const productSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addProductAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProductAsync.fulfilled, (state, action) => ({
        ...state,
        loading: false,
      }))
      .addCase(addProductAsync.rejected, (state, action) => {
        state.error =action.payload
      })
      .addCase(updateProductAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProductAsync.fulfilled, (state, action) => {
        state.loading = false;

        // Find the index of the updated product in the state array
        const index = state.products.findIndex(
          (product) => product.id === action.payload.id
        );

        // If the product is found, update it with the new data
        if (index !== -1) {
          state.products[index] = {
            ...state.products[index],
            ...action.payload,
            numUsers: null, // You might want to reset numUsers here if needed
          };
        }

        return state;
      })
      .addCase(updateProductAsync.rejected, (state, action) => {
        toast.error(action.payload?.message|| 'Fialed to update product');
      })
      .addCase(fetchAllProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload?.map((product) => ({
          ...product,
          numUsers: null,
        }));
      })
      .addCase(fetchAllProducts.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload.error);
      })
      .addCase(deleteProductAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProductAsync.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(action.payload.message);
      })
      .addCase(fetchNumProductUses.fulfilled, (state, action) => {
        const { payload } = action;
        const product_id = action.meta.arg.product_id;

        // Find the product in state and update the numUsers
        state.products = state.products.map((product) =>
          product.id === product_id
            ? {
                ...product,
                numUsers:
                  typeof payload === "number" ? payload : product.numUsers,
              }
            : product
        );

        state.loading = false;
      })
      .addCase(fetchNumProductUsesFailed, (state, action) => {
        state.loading = false;
        // Handle the error state or display a toast message
        toast.error(action.payload.message || "Failed to fetch product data");
      });
  },
});

// export const { removeProduct } = productSlice.actions;

export default productSlice.reducer;
