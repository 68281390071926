// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu .item {
 display: flex;
 flex-direction: column;
 gap: 10px;
 margin-bottom: 20px;
}

.menu .item.title {
 font-size: 12px;
 font-weight: 200;
 color: var(--soft-color);
 text-transform: uppercase;

 /* @include lg{
       display: none;
     } */
}
button.listItem{
  background: none;
    color: #fff;
    border: none;
}
.listItem {
 display: flex;
 align-items: center;
 gap: 10px;
 padding: 10px;
 border-radius: 5px;
}
 .listItem :hover {
  background-color: var(--soft-bg);
 }


 .fa-arrow-right-from-bracket{
  font-size: 20px
 }
 /* .listItemTitle @include lg {
   display: none;
  } */

`, "",{"version":3,"sources":["webpack://./src/components/menu/menu.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,SAAS;CACT,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,wBAAwB;CACxB,yBAAyB;;CAEzB;;QAEO;AACR;AACA;EACE,gBAAgB;IACd,WAAW;IACX,YAAY;AAChB;AACA;CACC,aAAa;CACb,mBAAmB;CACnB,SAAS;CACT,aAAa;CACb,kBAAkB;AACnB;CACC;EACC,gCAAgC;CACjC;;;CAGA;EACC;CACD;CACA;;KAEI","sourcesContent":[".menu .item {\n display: flex;\n flex-direction: column;\n gap: 10px;\n margin-bottom: 20px;\n}\n\n.menu .item.title {\n font-size: 12px;\n font-weight: 200;\n color: var(--soft-color);\n text-transform: uppercase;\n\n /* @include lg{\n       display: none;\n     } */\n}\nbutton.listItem{\n  background: none;\n    color: #fff;\n    border: none;\n}\n.listItem {\n display: flex;\n align-items: center;\n gap: 10px;\n padding: 10px;\n border-radius: 5px;\n}\n .listItem :hover {\n  background-color: var(--soft-bg);\n }\n\n\n .fa-arrow-right-from-bracket{\n  font-size: 20px\n }\n /* .listItemTitle @include lg {\n   display: none;\n  } */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
