import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const isDevelopment = window.location.hostname.includes('.dev') || window.location.hostname.includes('localhost');
const BASE_URL = isDevelopment ? "https://api.dev.siinlab.com" : "https://api.siinlab.com";

export const loginUser = createAsyncThunk(
 "user/login",
 async ({ email, password }, thunkAPI) => {
   const formData = new FormData();
   formData.append("grant_type", "");
   formData.append("username", email);
   formData.append("password", password);
   formData.append("scope", "");
   formData.append("client_id", "");
   formData.append("client_secret", "");

   try {
     const response = await axios.post(
       `${BASE_URL}/login`,
       formData,
       {
         headers: {
           "Content-Type": "application/x-www-form-urlencoded",
         },
       }
     );

     if (response.status === 200) {
       const data = response.data;
       console.log(response);
       localStorage.setItem("token", data.access_token);
       return data;
     } else {
       return thunkAPI.rejectWithValue("Invalid email or password.");
     }

   } catch (error) {
     console.log(error);
     toast.error(error.response.data.detail)
     return thunkAPI.rejectWithValue("An error occurred while logging in.");
   }
 }
);

// initialize userToken from local storage
const token = localStorage.getItem("token")
 ? localStorage.getItem("token")
 : null;

const authSlice = createSlice({
 name: "auth",
 initialState: {
   user: null,
   token,
   error: null,
   loading: 'idle',
 },
 reducers: {
   logOut: (state) => {
     state.user = null;
     state.token = null;
     state.error = null;
     state.loading = 'idle'
     localStorage.removeItem("token");
   },
 },
 extraReducers: (builder) => {
   builder
     .addCase(loginUser.pending, (state) => {
       state.loading = 'Laoding';
       state.error = null;
     })
     .addCase(loginUser.fulfilled, (state, action) => {
       state.loading = false;
       state.user = action.payload.user;
       state.token = action.payload.access_token;
       console.log(action.payload);
     })
     .addCase(loginUser.rejected, (state, action) => {
       console.log(action.payload);
         state.error = action.payload; // Set the error message for invalid credentials
     })

 },
});

export const { logOut } = authSlice.actions;
export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const loading = (state) => state.auth.loading;
// export const userSelector = (state)=> state.auth
export default authSlice.reducer;
