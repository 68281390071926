import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// const BASE_URL = 'https://dev.siinlab.com'

const isDevelopment =
  window.location.hostname.includes(".dev") ||
  window.location.hostname.includes("localhost");
const BASE_URL = isDevelopment
  ? "https://api.dev.siinlab.com/v1"
  : "https://api.siinlab.com/v1";

export const fetchUsersCount = createAsyncThunk(
  "users/fetchUsersCount",
  async (token, thunkAPI) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/count`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        //  console.log(response);
        return data;
      }
    } catch (error) {
      console.log(error);
      toast.error(
        `User Count ${error.response.data.detail} Please try again later.`
      );
      return thunkAPI.rejectWithValue(
        "Somthing went wrong, Please try again later."
      );
    }
  }
);

export const fetchUsersListAccount = createAsyncThunk(
  "users/fetchUsersListAccount",
  async (token, thunkAPI) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/list-accounts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        console.log(response);
        return data;
      }
    } catch (error) {
      console.log(error);
      toast.error(
        `User Count ${error.response.data.detail} Please try again later.`
      );
      return thunkAPI.rejectWithValue(
        "Somthing went wrong, Please try again later."
      );
    }
  }
);

export const makeSuperAdmin = createAsyncThunk(
  "users/fetchSuperAdmin",
  async ({ email, token }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/super-admin?email=${email}`,
        '',
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        toast.success("Account updated successfully")
        return data;
      }
    } catch (error) {
      console.error(error);
      toast.error(
        `Error fetching super admin: ${error.response.data.detail}. Please try again later.`
      );
      return thunkAPI.rejectWithValue(
        "Something went wrong. Please try again later."
      );
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "users/deleteAccount",
  async ({ email, token }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/super-admin?email=${email}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        toast.success("Account removed successfully")
        return data;
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.detail);
      return thunkAPI.rejectWithValue(
        "Something went wrong. Please try again later."
      );
    }
  }
);

export const changeUSerBalnce = createAsyncThunk(
  "users/changeUSerBalnce",
  async ({ email,balance, token }, thunkAPI) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/change-user-balance?email=${encodeURIComponent(email)}&balance=${balance}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        toast.success(`${email} balance changed successfully`)
        return data;
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.detail);
      return thunkAPI.rejectWithValue(
        "Something went wrong. Please try again later."
      );
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: null,
    error: null,
    loading: "idle",
    listAccounts: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsersCount.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
        // state.token = action.payload.access_token;
        console.log(action.payload);
      })
      .addCase(fetchUsersCount.rejected, (state, action) => {
        state.error = action.payload; // Set the error message for invalid credentials
      })
      .addCase(fetchUsersListAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsersListAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.listAccounts = action.payload;
        // state.token = action.payload.access_token;
        console.log(action.payload);
      })
      .addCase(fetchUsersListAccount.rejected, (state, action) => {
        state.error = action.payload; // Set the error message for invalid credentials
      })
      .addCase(makeSuperAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(makeSuperAdmin.fulfilled, (state, action) => {
        state.loading = false;
        const updatedUser = action.payload;
        const updatedList = state.listAccounts.map((user) =>
          user.email === updatedUser.email
            ? { ...user, name: updatedUser.name, email: updatedUser.email, role: updatedUser.role }
            : user
        );
        state.listAccounts = updatedList;
        console.log(action.payload);
      })
      .addCase(makeSuperAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.loading = false;
        const updatedUser = action.payload;
        const updatedList = state.listAccounts.map((user) =>
          user.email === updatedUser.email
          ? {...user, name: updatedUser.name, email: updatedUser.email, role: updatedUser.role} : user
        );
        state.listAccounts = updatedList;;
        console.log(action.payload);
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(changeUSerBalnce.pending, (state)=>{
        state.loading =true
        state.error = null
      })
      .addCase(changeUSerBalnce.fulfilled, (state, action)=>{
        state.loading =false
        const updatedUser = action.payload;
        const updatedList = state.listAccounts.map((user) =>
          user.email === updatedUser.email
          ? {...user, email: updatedUser.email, balance: updatedUser.balance/1000} : user
        );
        state.listAccounts = updatedList;;
        // state.error = null
      })
      .addCase(changeUSerBalnce.rejected, (state, action)=>{
        state.loading =false
        state.error = action.payload
      })

  },
});

export const usersCount = (state) => state.users.users;
export const loading = (state) => state.users.loading;
// export const userSelector = (state)=> state.auth
export default usersSlice.reducer;
