import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProducts, updateProductAsync } from "../../redux/products/productSlice";
import { toast } from "react-toastify";
import { selectCurrentToken } from "../../redux/users/authSlice";

function UpdateProductModal({ product, onClose, setOpen }) {
 const token  = useSelector(selectCurrentToken)
  const dispatch = useDispatch();
  const [updatedProductData, setUpdatedProductData] = useState({
    name: product.name,
    id: product.id,
    cost: product.cost,
    api_url: product.api_url,
    playground_url: product.playground_url,
    description: product.description,
    icon: product.icon,
  status: product.status
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log(updatedProductData);
      const productId = product.id
      await dispatch(updateProductAsync({ productData: updatedProductData, token}))
      dispatch(fetchAllProducts());
      toast.success("Product updated successfully");
      onClose();
    } catch (error) {

      toast.error("Faild to update product");
    }
  };

  const handleChange = (e) => {
    // console.log("Event:", e);
  // console.log("Current State:", updatedProductData);
    setUpdatedProductData({
      ...updatedProductData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="add">
      <div className="modal">
        <i
          className="close fa-solid fa-circle-xmark  "
          onClick={onClose}
        ></i>
        {/* <span className='close' onClick={()=> setOpen(false)}>X</span> */}
        <h1>Add New Product</h1>
        <form onSubmit={handleSubmit}>
          <div className="item">
            <label htmlFor="name">Id</label>
            <input
              required
              type="text"
              id="id"
              name="id"
              value={updatedProductData.id}
              onChange={handleChange}
              placeholder="Product id"
            />
          </div>
          <div className="item">
            <label htmlFor="name">Name</label>
            <input
              required
              type="text"
              id="name"
              name="name"
              value={updatedProductData.name}
              onChange={handleChange}
              placeholder="Product Name"
            />
          </div>
          <div className="item">
            <label htmlFor="cost">Cost</label>
            <input
              required
              type="number"
              id="cost"
              name="cost"
              value={updatedProductData.cost}
              onChange={handleChange}
              placeholder="Cost"
            />
          </div>
          <div className="item">
            <label htmlFor="apiUrl">API URL</label>
            <input
              required
              type="text"
              id="apiUrl"
              name="api_url"
              value={updatedProductData.api_url}
              onChange={handleChange}
              placeholder="Api Url"
            />
          </div>
          <div className="item">
            <label htmlFor="playgroundUrl">Playground URL</label>
            <input
              required
              type="text"
              id="playgroundUrl"
              name="playground_url"
              value={updatedProductData.playground_url}
              onChange={handleChange}
              placeholder="Playground URL"
            />
          </div>
          <div className="item">
            <label htmlFor="description">Description</label>
            <input
              required
              type="text"
              name="description"
              id="description"
              value={updatedProductData.description}
              onChange={handleChange}
              placeholder="Description"
            />
          </div>
          <div className="item">
            <label htmlFor="status">status</label>
            <input
              required
              type="text"
              name="status"
              id="status"
              value={updatedProductData.status}
              onChange={handleChange}
              placeholder="status"
            />
          </div>
          <div className="item">
            <label htmlFor="icon">icon</label>
            <input
              required
              type="text"
              name="icon"
              id="icon"
              value={updatedProductData.icon}
              onChange={handleChange}
              placeholder="icon"
            />
          </div>

          <button className="send">Send</button>
        </form>
      </div>
    </div>
  );
}

export default UpdateProductModal;
