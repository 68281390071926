import React, { useState } from "react";
import "./add_product.css";
import { useDispatch, useSelector } from "react-redux";
import { addProductAsync, fetchAllProducts } from "../../redux/products/productSlice";
import { toast } from "react-toastify";
import { selectCurrentToken } from "../../redux/users/authSlice";
function AddProduct({ setOpen }) {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken)
  const [productData, setProductData] = useState({
    id:"",
    name: "",
    cost: "",
    api_url: "",
    playground_url: "",
    description: "",
    icon: "",
  status: ""
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(productData);
    try {
      await dispatch(addProductAsync({productData: productData,  token }));
      console.log(token);
      dispatch(fetchAllProducts())
      setOpen(false);
      // toast.success("Product Add Succesfully")
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    // Update the state with the new input  value

    setProductData({
      ...productData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="add">
      <div className="modal">
        <i
          className="close fa-solid fa-circle-xmark  "
          onClick={() => setOpen(false)}
        ></i>
        {/* <span className='close' onClick={()=> setOpen(false)}>X</span> */}
        <h1>Add New Product</h1>
        <form onSubmit={handleSubmit}>
          <div className="item">
            <label htmlFor="id">id</label>
            <input
            required
              type="text"
              id="id"
              name="id"
              value={productData.id}
              onChange={handleChange}
              placeholder="Product id"
            />
          </div>
          <div className="item">
            <label htmlFor="name">Name</label>
            <input
            required
              type="text"
              id="name"
              name="name"
              value={productData.name}
              onChange={handleChange}
              placeholder="Product Name"
            />
          </div>
          <div className="item">
            <label htmlFor="cost">Cost</label>
            <input
            required
              type="number"
              id="cost"
              name="cost"
              value={productData.cost}
              onChange={handleChange}
              placeholder="Cost"
            />
          </div>
          <div className="item">
            <label htmlFor="apiUrl">API URL</label>
            <input
            required
              type="text"
              id="apiUrl"
              name="api_url"
              value={productData.api_url}
              onChange={handleChange}
              placeholder="Api Url"
            />
          </div>
          <div className="item">
            <label htmlFor="playgroundUrl">Playground URL</label>
            <input
            required
              type="text"
              id="playgroundUrl"
              name="playground_url"
              value={productData.playground_url}
              onChange={handleChange}
              placeholder="Playground URL"
            />
          </div>
          <div className="item">
            <label htmlFor="description">Description</label>
            <input
            required
              type="text"
              name="description"
              id="description"
              value={productData.description}
              onChange={handleChange}
              placeholder="Description"
            />
          </div>
          <div className="item">
            <label htmlFor="status">status</label>
            <input
            required
              type="text"
              name="status"
              id="status"
              value={productData.status}
              onChange={handleChange}
              placeholder="status"
            />
          </div>
          <div className="item">
            <label htmlFor="icon">icon</label>
            <input
            required
              type="text"
              name="icon"
              id="icon"
              value={productData.icon}
              onChange={handleChange}
              placeholder="icon"
            />
          </div>

          <button className="send" >Send</button>
        </form>
      </div>
    </div>
  );
}

export default AddProduct;
