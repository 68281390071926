// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputs-balance {
 display: flex;
 justify-content: center;
 align-items: center;
 background-color: #fff;
 padding: 5px;
 margin: 10px 0;
 border-radius: 8px;
 border: 1px solid #BBB;
 background: #FBFBFB;
 /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15); */

}
.inputs-balance button{
 border: none;
 /* background: none; */
 width: 100%;
 padding: 5px;
}
.inputs-balance button:hover{
 background-color:#000;
 color: #fff;
}
.inputs-balance input {
 border: none;
 background-color: #FBFBFB;
 outline: none;
 width: 13em;
 /* padding: 10px; */
 /* border-radius: 8px; */
}
`, "",{"version":3,"sources":["webpack://./src/pages/users/users_list.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,sBAAsB;CACtB,YAAY;CACZ,cAAc;CACd,kBAAkB;CAClB,sBAAsB;CACtB,mBAAmB;CACnB,qDAAqD;;AAEtD;AACA;CACC,YAAY;CACZ,sBAAsB;CACtB,WAAW;CACX,YAAY;AACb;AACA;CACC,qBAAqB;CACrB,WAAW;AACZ;AACA;CACC,YAAY;CACZ,yBAAyB;CACzB,aAAa;CACb,WAAW;CACX,mBAAmB;CACnB,wBAAwB;AACzB","sourcesContent":[".inputs-balance {\n display: flex;\n justify-content: center;\n align-items: center;\n background-color: #fff;\n padding: 5px;\n margin: 10px 0;\n border-radius: 8px;\n border: 1px solid #BBB;\n background: #FBFBFB;\n /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15); */\n\n}\n.inputs-balance button{\n border: none;\n /* background: none; */\n width: 100%;\n padding: 5px;\n}\n.inputs-balance button:hover{\n background-color:#000;\n color: #fff;\n}\n.inputs-balance input {\n border: none;\n background-color: #FBFBFB;\n outline: none;\n width: 13em;\n /* padding: 10px; */\n /* border-radius: 8px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
