import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./users/authSlice";
import usersSlice from "./users/usersSlice";
import productSlice from "./products/productSlice";


const store = configureStore({
 reducer: {
  auth: userSlice,
  users: usersSlice,
  products: productSlice
 }
})


export default store
