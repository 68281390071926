import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changeUSerBalnce, deleteAccount, fetchUsersListAccount, makeSuperAdmin, usersCount } from '../../redux/users/usersSlice';
import { selectCurrentToken } from "../../redux/users/authSlice";
// import { toast } from "react-toastify";
import deleteIcon from "../../img/icon/delete.svg"
import updateIcon from "../../img/icon/view.svg"
import './users_list.css'


function UsersList() {
 const userCount = useSelector(usersCount)
 const listAccounts = useSelector((state) => state.users.listAccounts);
 const token = useSelector(selectCurrentToken);
 const dispatch = useDispatch();
 const [changedBalances, setChangedBalances] = useState({});

 function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  // Parse the input date string
  const inputDate = new Date(dateString);



  const formattedDate = inputDate.toLocaleString("en-US", options);

  // Replace the slash (/) with a hyphen (-) and remove commas
  const withoutComma = formattedDate.replace(/[\/]/g, "-");

  return withoutComma.replace(/[\,]/g, " ");
}

 useEffect(() => {
   dispatch(fetchUsersListAccount(token));
 }, [dispatch,token]);

 const handleSuperAdmin = (email)=>{
   dispatch(makeSuperAdmin({email, token}))
 }
 const handleDeleteAdminAccount = async (email) => {
    await dispatch(deleteAccount({ email, token }));
};
 const handleChangeUSerBalnce = async (email) => {
  const balance = changedBalances[email];
  console.log("balance==>",balance, "token==>",token);
    await dispatch(changeUSerBalnce({ email,balance, token }));
};
 return (
   <>
    <div className="home">
        <div className="box numberOfUsers"><span>Total Users: <b> {userCount?.count}</b></span></div>
    </div>
       <table className="product-table">
         <thead>
           <tr>
             <th>Name</th>
             <th>Email</th>
             <th>Balance </th>
             <th>Change Balance </th>
             <th>Role </th>
             <th>Date of creation </th>
             <th>Make Super Admin</th>
             <th>Remove Super Admin</th>
           </tr>
         </thead>
         <tbody>
           {listAccounts &&
             listAccounts.map((user) => (
               <tr key={user.email}>
                 <td>{user.name}</td>
                 <td>{user.email}</td>
                 <td>${user.balance}</td>
                 <td> <div className='inputs-balance'> <input
                    type="number"
                    placeholder="0.0$"
                    value={changedBalances[user.email] || ''}
                    onChange={(e) => setChangedBalances({ ...changedBalances, [user.email]: e.target.value })}
                  /> <button onClick={()=> handleChangeUSerBalnce(user.email)}>Change</button>
                  {/* ${user.balance} */}</div>
                  </td>
                 <td>{user.role}</td>
                 <td>{user.date_of_creation !== null && formatDate( user.date_of_creation)}</td>
                  <td className='text-center'><img src={updateIcon} alt="update icon" onClick={()=> handleSuperAdmin(user.email)}  /></td>
                 <td className='text-center'><img src={deleteIcon} alt="delete icon" onClick={()=>handleDeleteAdminAccount(user.email)} /></td>
               </tr>
             ))}
         </tbody>
       </table>
               {/* Render the modal here */}
     {/* {selectedUser  && ( */}
       {/* <UpdateProductModal product={selectedProduct} setOpen={setOpen} onClose={() => setSelectedProduct(null)} /> */}
     {/* )} */}
   </>
 );

}

export default UsersList

