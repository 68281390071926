import axios from "axios";
import { toast } from "react-toastify";
// const BASE_URL = "https://dev.siinlab.com";
const isDevelopment = window.location.hostname.includes('.dev') || window.location.hostname.includes('localhost');
const BASE_URL = isDevelopment ? "https://api.dev.siinlab.com/v1" : "https://api.siinlab.com/v1";

// fetch All Products
export const fetchProducts = async () => {
  console.log(BASE_URL);
  try {
    const response = await axios.get(`${BASE_URL}/product/list`, {
      headers: {
        accept: "application/json",
      },
    });

    const data = response.data;
    console.log(response);
    return data;
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

// Add Product
export const addProduct = async (productData, token) => {
  try {

    const response = await axios.post(
      `${BASE_URL}/admin/product`,
      productData,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log('vjkfnfjkvnfjkvndfjkvfn',response);
    if (response.status === 200) {
      toast.success("product added succesfully");

      return response.data;
    }

  } catch (error) {
    if (error.isAxiosError && error.response === undefined) {
      // Network error
      console.error("Network error:", error.message);
      toast.error("Network error. Please check your internet connection.");
    } else {
      // Non-network error (possibly CORS-related)
      console.error("Error:", error.message);
      toast.error("An error occurred while processing your request.");
    }
  }
};
// UPDATE Product
export const updateProduct = async ({  productData, token }) => {
  try {
    const response = await axios.patch(`${BASE_URL}/admin/product`,

      productData, // Pass an empty object as the second argument since the data is in the URL
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      toast.success("Product updated successfully");
    }

    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      toast.error("Required field");
    }
    toast.error(error.response.data.detail);
  }
};

// Delete product
export const deleteProduct = async (product_id, token) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/admin/product`,
     {
      data:{product_id},

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    toast.error(error.response.data.detail);
  }
};
// fetch number of users using each product
// productServices.js
export const fetchNumProductUsing = async (product_id, token) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/admin/users/${product_id}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error; // Throw the error to handle it in the calling function
  }
};

