// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.home {
    display: grid;
    grid-template-columns: repeat(2, 1fr) repeat(2, 50px) repeat(2, 1fr);
    grid-template-rows: 50px repeat(4, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
 /* grid-template-columns: repeat(2, 1fr); */
 /* grid-auto-rows:1fr 1fr 1fr; */
 /* grid-template-columns: 1fr 6fr;
 grid-auto-rows: minmax(50px, auto);
 grid-auto-flow: dense; */
}

.home .box {
 padding: 20px;
 border-radius: 10px;
 border: 2px solid #384256;
}

.home .box.numberOfUsers{
    padding: 5px;
}

.home .box.numberOfUsers span{
    font-weight: 400;
    font-size: 12px;
}

.home .numberOfUsers{
    grid-area: 1 / 3 / 1 / 5;
}

.home .addProduct{
    grid-area: 2 / 1 / 5 / 4;
}
.home .usersProducs{
    grid-area: 2 / 4 / 5 / 8;
}
.add-product{
 display: flex;
 justify-content: center;
 margin: 10px;
 padding: 10px;
}

.add-product button{
 padding: 5px;
    background: var(--main-bg);
    color: #fff;
    border-radius: 5px;
}


.product-table{
    width: 95%;
    border-collapse: separate;
    margin: 20px auto;
   }

   .product-table th,
   .product-table td{
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #2299e9;
   }

   .product-table tbody tr:hover {
    background-color: #f5f5f5;
   }
`, "",{"version":3,"sources":["webpack://./src/pages/home/home.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,oEAAoE;IACpE,uCAAuC;IACvC,oBAAoB;IACpB,iBAAiB;CACpB,2CAA2C;CAC3C,gCAAgC;CAChC;;yBAEwB;AACzB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,yBAAyB;AAC1B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B;AACA;CACC,aAAa;CACb,uBAAuB;CACvB,YAAY;CACZ,aAAa;AACd;;AAEA;CACC,YAAY;IACT,0BAA0B;IAC1B,WAAW;IACX,kBAAkB;AACtB;;;AAGA;IACI,UAAU;IACV,yBAAyB;IACzB,iBAAiB;GAClB;;GAEA;;IAEC,YAAY;IACZ,gBAAgB;IAChB,gCAAgC;GACjC;;GAEA;IACC,yBAAyB;GAC1B","sourcesContent":["\n.home {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr) repeat(2, 50px) repeat(2, 1fr);\n    grid-template-rows: 50px repeat(4, 1fr);\n    grid-column-gap: 5px;\n    grid-row-gap: 5px;\n /* grid-template-columns: repeat(2, 1fr); */\n /* grid-auto-rows:1fr 1fr 1fr; */\n /* grid-template-columns: 1fr 6fr;\n grid-auto-rows: minmax(50px, auto);\n grid-auto-flow: dense; */\n}\n\n.home .box {\n padding: 20px;\n border-radius: 10px;\n border: 2px solid #384256;\n}\n\n.home .box.numberOfUsers{\n    padding: 5px;\n}\n\n.home .box.numberOfUsers span{\n    font-weight: 400;\n    font-size: 12px;\n}\n\n.home .numberOfUsers{\n    grid-area: 1 / 3 / 1 / 5;\n}\n\n.home .addProduct{\n    grid-area: 2 / 1 / 5 / 4;\n}\n.home .usersProducs{\n    grid-area: 2 / 4 / 5 / 8;\n}\n.add-product{\n display: flex;\n justify-content: center;\n margin: 10px;\n padding: 10px;\n}\n\n.add-product button{\n padding: 5px;\n    background: var(--main-bg);\n    color: #fff;\n    border-radius: 5px;\n}\n\n\n.product-table{\n    width: 95%;\n    border-collapse: separate;\n    margin: 20px auto;\n   }\n\n   .product-table th,\n   .product-table td{\n    padding: 8px;\n    text-align: left;\n    border-bottom: 1px solid #2299e9;\n   }\n\n   .product-table tbody tr:hover {\n    background-color: #f5f5f5;\n   }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
