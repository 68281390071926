import React, { useEffect, useState } from "react";
import axios from "axios";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/users/authSlice";

const Histogram = () => {
  const isDevelopment = window.location.hostname.includes('.dev') || window.location.hostname.includes('localhost');
const BASE_URL = isDevelopment ? "https://api.dev.siinlab.com/v1" : "https://api.siinlab.com/v1";


  const [histogramData, setHistogramData] = useState([]);
  const token = useSelector(selectCurrentToken);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/admin/histogram`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setHistogramData(response.data);
      } catch (error) {
        console.error("Error fetching histogram data:", error);
      }
    };

    fetchData();
  }, [token]);

  const options = {
    chart: {
      type: "area",
      stacked: false,
          height: 250,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          },
          dataLabels: {
            enabled: false
          },
    },
    xaxis: {
      categories: histogramData.map((entry) => entry.balance.toString()),
      title: {
        text: "Balance",
      },
    },
    yaxis: {
      title: {
        text: "Number of Users",
      },
    },
  };

  const series = [
    {
      name: "Number of Users",
      type: 'line',
      data: histogramData.map((entry) => entry.number_of_users),
    },
  ];

  return (
    <div>
      <h2>Histogram</h2>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default Histogram;
