import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer theme="colored" position="top-center"></ToastContainer>
    {/* <BrowserRouter> */}
      <Provider store={store}>
        {/* <Routes> */}
        <App />
        {/* </Routes> */}
      </Provider>
    {/* </BrowserRouter> */}
  </React.StrictMode>
);

reportWebVitals();
