import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/users/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AuthHeader from "./AuthHeader";
import './styles/login.css'

function Login() {
  const { register, handleSubmit } = useForm();
  const { error, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const result = await dispatch(loginUser(data));
    if (loginUser.fulfilled.match(result)) {
      // Login was successful, navigate to the home page
      navigate("/");
    }
  };

  return (
    <section className="sign-form">
      <AuthHeader />
      <h1 className="form-title">Login</h1>
      <form className="sign-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="inputs-sign">
          {/* <label htmlFor="email">Email:</label> */}
          <i className="fa-solid fa-at"></i>
          <input type="text" id="email" placeholder="name@gmail.com" {...register("email")} required />
        </div>
        <div className="inputs-sign">
          {/* <label htmlFor="password">Password:</label> */}
          <i className="fa-solid fa-user-lock"></i>
          <input
            type="password"
            id="password"
            placeholder="Your Password"
            {...register("password")}
            required
          />
        </div>

        {/* Display the error message if it exists */}
        {/* {error && <p className="errmsg" aria-live="assertive">{error}</p>} */}
        {/* <div className="sin-buttons"> */}
        <button className="sign-btn">
          LOG IN <i className="fa-solid fa-chevron-right"></i>
        </button>
        {/* </div> */}
        {/* <p className="already-sign">
          Need an Account?
          <br />
          <span className="line">
            <Link to={"/user/register"}>Sign Up</Link>
          </span>
        </p> */}
      </form>
    </section>
  );
}

export default Login;
