// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.724);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add .modal {
  padding: 50px;
  border-radius: 10px;
  background-color: var(--main-bg);
  position: relative;
  z-index: 100;
}

.add .modal h1 {

  margin-bottom: 40px;
  font-size: 24px;
  color: var(--soft-color);

}

.modal .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
}

.modal form {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  justify-content: space-between;
}

.modal .item {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.modal .item label {
  font-size: 14px;
}

.modal .item input {

  padding: 10px;
  background-color: transparent;
  color: white;
  outline: none;
  border: 1px solid var(--soft-color);
  border-radius: 3px;

}

.modal form button {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  padding: 5px;
  background: var(--main-bg);
  color: #fff;
  border-radius: 5px;

}
`, "",{"version":3,"sources":["webpack://./src/components/modal/add_product.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,sCAAsC;EACtC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gCAAgC;EAChC,kBAAkB;EAClB,YAAY;AACd;;AAEA;;EAEE,mBAAmB;EACnB,eAAe;EACf,wBAAwB;;AAE1B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,aAAa;EACb,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,mCAAmC;EACnC,kBAAkB;;AAEpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,YAAY;EACZ,0BAA0B;EAC1B,WAAW;EACX,kBAAkB;;AAEpB","sourcesContent":[".add {\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-color: rgba(0, 0, 0, 0.724);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.add .modal {\n  padding: 50px;\n  border-radius: 10px;\n  background-color: var(--main-bg);\n  position: relative;\n  z-index: 100;\n}\n\n.add .modal h1 {\n\n  margin-bottom: 40px;\n  font-size: 24px;\n  color: var(--soft-color);\n\n}\n\n.modal .close {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n  color: #fff;\n}\n\n.modal form {\n  display: flex;\n  flex-wrap: wrap;\n  max-width: 500px;\n  justify-content: space-between;\n}\n\n.modal .item {\n  width: 40%;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  margin-bottom: 20px;\n}\n\n.modal .item label {\n  font-size: 14px;\n}\n\n.modal .item input {\n\n  padding: 10px;\n  background-color: transparent;\n  color: white;\n  outline: none;\n  border: 1px solid var(--soft-color);\n  border-radius: 3px;\n\n}\n\n.modal form button {\n  width: 100%;\n  padding: 10px;\n  cursor: pointer;\n  padding: 5px;\n  background: var(--main-bg);\n  color: #fff;\n  border-radius: 5px;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
