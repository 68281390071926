import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductAsync, fetchAllProducts, fetchNumProductUses } from "../../redux/products/productSlice";
import { selectCurrentToken } from "../../redux/users/authSlice";
import { toast } from "react-toastify";
import deleteIcon from "../../img/icon/delete.svg"
import updateIcon from "../../img/icon/view.svg"
import UpdateProductModal from "../../components/modal/UpdateProductModal";

function Products() {
  const allProducts = useSelector((state) => state.products.products);
  const token = useSelector(selectCurrentToken);
  const dispatch = useDispatch();

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [open, setOpen] = useState(false)
  const memoizedProducts = useMemo(() => allProducts, [allProducts]);

  useEffect(() => {
    dispatch(fetchAllProducts());
  }, [dispatch,]);

  useEffect(() => {
    const promises = memoizedProducts.map(async (product) => {
      // Check if numUsers is already fetched for the product
      if (product.numUsers === null) {
        await dispatch(fetchNumProductUses({ product_id: product.id, token }));
        console.log('dtechNum');
      }
    });

    Promise.all(promises);
  }, [dispatch, memoizedProducts, token]);


  const handleDelete = async (product_id) => {
    try {
      console.log(token);
      await dispatch(deleteProductAsync({product_id, token}));
      const deletedProduct = allProducts.find((product) => product.id === product_id);
      if (deletedProduct) {
        dispatch(fetchAllProducts());
      }
    } catch (error) {
      toast.error("Failed to delete product");
    }
  };

  const handleUpdate = (product)=>{
    setSelectedProduct(product)
    // setOpen(true)
  }
  return (
    <>

        <table className="product-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Cost</th>
              <th>Product Using</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {allProducts &&
              allProducts.map((product) => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>${product.cost}</td>
                  <td className='text-center'>{product.numUsers}</td>
                  <td className='text-center'><img src={updateIcon} alt="update icon" onClick={()=> handleUpdate(product)}  /></td>
                  <td className='text-center'><img src={deleteIcon} alt="delete icon" onClick={()=>handleDelete(product.id)} /></td>
                </tr>
              ))}
          </tbody>
        </table>
                {/* Render the modal here */}
      {selectedProduct  && (
        <UpdateProductModal product={selectedProduct} setOpen={setOpen} onClose={() => setSelectedProduct(null)} />
      )}
    </>
  );
}

export default Products;
