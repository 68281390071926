// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 10px;
 border-top: 1px solid #384256;
 position: absolute;
 bottom: 0;
 left: 0;
 right: 0;
 background-color: var(--main-bg);
 color: #fff;
}


span:first-child {
 font-weight: bold;
}

span:last-child {
 font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;CAC9B,aAAa;CACb,6BAA6B;CAC7B,kBAAkB;CAClB,SAAS;CACT,OAAO;CACP,QAAQ;CACR,gCAAgC;CAChC,WAAW;AACZ;;;AAGA;CACC,iBAAiB;AAClB;;AAEA;CACC,eAAe;AAChB","sourcesContent":[".footer {\n display: flex;\n align-items: center;\n justify-content: space-between;\n padding: 10px;\n border-top: 1px solid #384256;\n position: absolute;\n bottom: 0;\n left: 0;\n right: 0;\n background-color: var(--main-bg);\n color: #fff;\n}\n\n\nspan:first-child {\n font-weight: bold;\n}\n\nspan:last-child {\n font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
